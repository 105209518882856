import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Last day of Test Week!`}</strong></p>
    <p>{`30 minutes to establish a max:`}</p>
    <p>{`Push Press and Max Height Box Jump`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`“Grace”`}</em></p>
    <p>{`30 Clean & Jerks for time. (135/95)`}</p>
    <p>{`then,`}</p>
    <p>{`Max HSPU’s, 1 attempt `}<em parentName="p">{`(resting no longer than :01 on head at bottom of
each rep)`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Tomorrow starts our “True Deload” week.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      